import { Box, Flex, Heading } from '@chakra-ui/react';
import * as React from 'react';
import { Testimonial, TestimonialCard } from './TestimonialCard';

const testimonials: Testimonial[] = [
  {
    name: 'Peter',
    businessName: 'Victory Homes',
    color: 'brand.pink',
    quote:
      'Site inductions are so easy now. I never have to stop work. I just ask the subbie to scan the QR code and Canyou does the rest.',
  },
  {
    name: 'Megan',
    businessName: "Megz's Bookkeeping",
    color: 'brand.orange',
    quote:
      'I signed up on the day and it took me about 10 minutes to setup the business. I onboarded 17 staff in one day. I can’t believe how easy this was.',
  },
  {
    name: 'Anthony',
    businessName: 'The Food Forum',
    color: 'brand.yellow',
    quote:
      'Now I’m confident my staff are job-ready and up to date with our latest business information.',
  },
];

export const TestimonialSection: React.FC = () => {
  return (
    <Flex direction="column" pt={8} px={5} pb={{ base: '20px', md: '50px' }}>
      <Heading textAlign="center" fontSize={{ base: '42px', md: '56px' }}>
        <Box as="span">
          What our customers say
          <br /> about{' '}
        </Box>
        <Box as="span" color="brand.blue">
          Canyou.
        </Box>
      </Heading>
      <Flex
        py={8}
        px={{ base: 0, md: 5 }}
        justify="center"
        align="center"
        flexWrap="wrap"
        gridGap={{ base: '25px', md: '50px' }}
        direction={{ base: 'column', md: 'row' }}
      >
        {testimonials.map(({ businessName, name, quote, color }) => (
          <TestimonialCard
            key={name}
            businessName={businessName}
            name={name}
            quote={quote}
            color={color}
          />
        ))}
      </Flex>
    </Flex>
  );
};
