import * as React from 'react';
import { Button, Flex, Heading, Stack, Text, Box } from '@chakra-ui/react';
import { getConfig } from '../../config';

type HeroTextProps = {
  heading: string;
  subHeading: string;
};

const config = getConfig();

export const HeroText: React.FC<HeroTextProps> = ({ heading, subHeading }) => {
  return (
    <Flex
      direction="column"
      justify="center"
      backgroundImage="url(/canyou-dog.svg)"
      backgroundRepeat="no-repeat"
      backgroundPosition="-350px 40px"
      flex={1}
      backgroundSize="880px"
    >
      <Flex direction="column" py={5} px={{ base: 2, md: 4, xl: '80px' }}>
        <Heading as="h1" p={3} textAlign={{ base: 'center', lg: 'left' }}>
          {heading}
        </Heading>
        <Text p={3} fontSize="24px" textAlign={{ base: 'center', lg: 'left' }}>
          {subHeading}
        </Text>
        <Stack
          spacing={3}
          p={3}
          direction={{ base: 'column', lg: 'row' }}
          align="center"
        >
          <Box>
            <Button as="a" href={`${config.platformUrl}/signup`} size="lg">
              Try for free
            </Button>
          </Box>
          <Box>
            <Button
              as="a"
              href={`${config.demoBookingUrl}`}
              size="lg"
              variant="outline"
            >
              Book a demo
            </Button>
          </Box>
        </Stack>
      </Flex>
    </Flex>
  );
};
