import * as React from 'react';
import { Flex } from '@chakra-ui/react';

export const HeroImage: React.FC<{ heroImage: React.ReactNode }> = ({
  heroImage,
}) => (
  <Flex bg="brand.blue" flex={1} py="50px" pl="30px" justify="flex-end">
    {heroImage}
  </Flex>
);
