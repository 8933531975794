import { Flex, Text, Icon } from '@chakra-ui/react';
import * as React from 'react';
import { IoMdQuote } from 'react-icons/io';

export type Testimonial = {
  name: string;
  businessName: string;
  quote: string;
  color: string;
};

type TestimonialCardProps = Testimonial;

export const TestimonialCard: React.FC<TestimonialCardProps> = ({
  businessName,
  name,
  quote,
  color,
}) => {
  return (
    <Flex
      direction="column"
      transition="all .2s ease-in-out"
      px={{ base: 0, md: 8 }}
      py={8}
      height="450px"
      borderColor="brand.black"
      border="1px solid"
      bg={color}
      borderRadius="69px"
      justify="space-between"
      align="center"
      boxShadow="0px 34px 84px #193F9214"
      _hover={{
        transform: 'scale(1.03)',
      }}
    >
      <Icon as={IoMdQuote} width={10} height={10} />
      <Text
        p={3}
        fontWeight="500"
        fontSize="24px"
        width="320px"
        textAlign={{ base: 'center', md: 'left' }}
      >
        {quote}
      </Text>
      <Text p={3} fontWeight="600" textAlign="center">
        {name}
        <br />
        {businessName}
      </Text>
    </Flex>
  );
};
