import { Heading, Box, Text, Flex, Icon, Divider } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import CanyouLogo from '../../../assets/canyou-blue-logo.svg';
import EmployeeOnboarding from '../../../assets/icons/employee-onboarding.svg';
import CustomerOnboarding from '../../../assets/icons/customer-onboarding.svg';
import SupplierOnboarding from '../../../assets/icons/supplier-onboarding.svg';
import ClientIdentity from '../../../assets/icons/client-identity-verification.svg';
import Licence from '../../../assets/icons/licence-cert-management.svg';
import TeamKnowledge from '../../../assets/icons/team-knowledge.svg';
import BankAccount from '../../../assets/icons/bank-account.svg';
import ClientIdentityPink from '../../../assets/icons/client-identity-pink.svg';
import Esign from '../../../assets/icons/esign.svg';
import FormBuilder from '../../../assets/icons/form-builder.svg';
import Notifications from '../../../assets/icons/notifications.svg';
import SuperChoice from '../../../assets/icons/super-choice.svg';
import TaxFileNumber from '../../../assets/icons/tax-file-number.svg';
import Video from '../../../assets/icons/video.svg';
import { Integrations } from '../../Integrations';

type Data = {
  happyUserNotification: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const templateDetails = [
  { text: 'Employee onboarding', icon: EmployeeOnboarding, color: '#FFADA1' },
  {
    text: 'Client & customer onboarding',
    icon: CustomerOnboarding,
    color: '#8CFFA5',
  },
  {
    text: 'Client identity verification',
    icon: ClientIdentity,
    color: '#25BEFF',
  },
  { text: 'Supplier onboarding', icon: SupplierOnboarding, color: '#DA9DFF' },
  {
    text: 'Team training and knowledge sharing',
    icon: TeamKnowledge,
    color: '#9FFF25',
  },
  {
    text: 'Licence and cert. management',
    icon: Licence,
    color: '#FF7325',
  },
];

const customisableTaskDetails = [
  { text: 'Form builder', icon: FormBuilder },
  { text: 'E-sign', icon: Esign },
  { text: 'Video', icon: Video },
  { text: 'Notifications', icon: Notifications },
];

const standardTaskDetails = [
  {
    text: 'Client identity verification',
    icon: ClientIdentityPink,
  },
  { text: 'Tax file number declaration', icon: TaxFileNumber },
  { text: 'Super choice', icon: SuperChoice },
  { text: 'Bank account', icon: BankAccount },
];

const taskTileDetails = [
  { heading: 'Customisable Tasks', taskItems: customisableTaskDetails },
  { heading: 'Standard Tasks', taskItems: standardTaskDetails },
];

export const SolutionSection: React.FC = () => {
  const { happyUserNotification } = useStaticQuery<Data>(
    graphql`
      {
        happyUserNotification: file(
          relativePath: { eq: "features/happy-user-notifications.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );

  return (
    <>
      <Box
        pb={{ base: '35px', lg: '42px' }}
        pt={{ base: '32px', lg: '42px' }}
        bg="brand.egg"
      >
        <Text
          fontSize="32px"
          fontFamily="roboto"
          textAlign="center"
          pb="24px"
          px="16px"
        >
          The simple and affordable solution
        </Text>
        <Flex justify="center">
          <CanyouLogo width={260} />
        </Flex>
        <Box px={2} textAlign="center">
          <GatsbyImage
            alt="spreadsheet"
            image={happyUserNotification.childImageSharp.gatsbyImageData}
            style={{
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
          <Flex justify="center">
            <Heading
              as="h3"
              fontSize={['1.8em', '2.2em']}
              textAlign="center"
              px={{ base: 4 }}
              pt={{ base: '20px', lg: '32px' }}
              pb={{ base: '55px', lg: '32px' }}
              maxWidth="1000px"
            >
              <Box as="span">Automated </Box>
              <Box as="span" color="brand.blue">
                collection{' '}
              </Box>
              <Box as="span">and</Box>{' '}
              <Box as="span" color="brand.blue">
                monitoring{' '}
              </Box>{' '}
              <Box as="span">of </Box>
              <Box as="span" color="brand.blue">
                documents, forms, licences
              </Box>{' '}
              <Box as="span">and </Box>
              <Box as="span" color="brand.blue">
                certifications{' '}
              </Box>{' '}
              <Box as="span">to ensure the business stays compliant</Box>
            </Heading>
          </Flex>
        </Box>
      </Box>
      <Box
        pb={{ base: '35px', lg: '42px' }}
        pt={{ base: '32px', lg: '42px' }}
        bg="brand.light-gray"
        px="16px"
      >
        <Heading
          as="h3"
          size="lg"
          textAlign="center"
          px={{ base: 4, lg: '200px' }}
          pt={{ base: '20px', lg: '24px' }}
          pb={{ base: '4px' }}
        >
          <Box as="span">Smart </Box>
          <Box as="span" color="brand.blue">
            templates{' '}
          </Box>
          <Box as="span">for common processes</Box>
        </Heading>
        <Text
          fontSize={{ base: '24px', lg: '28px' }}
          fontFamily="roboto"
          textAlign="center"
          pb="24px"
          color="brand.blue"
        >
          We give you the foundations
        </Text>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          wrap="wrap"
          justify="center"
          align="center"
          gridGap="10"
          px={{ base: 0, xl: '130px' }}
        >
          {templateDetails.map(({ text, icon, color }) => (
            <TemplateTile key={text} icon={icon} text={text} color={color} />
          ))}
        </Flex>
      </Box>
      <Box
        pb={{ base: '35px', lg: '42px' }}
        pt={{ base: '32px', lg: '42px' }}
        px="16px"
        bg="brand.light-gray"
      >
        <Heading
          as="h3"
          size="lg"
          textAlign="center"
          px={{ base: 4, lg: '200px' }}
          pt={{ base: '20px', lg: '24px' }}
          pb={{ base: '4px' }}
        >
          <Box as="span">That can be easily </Box>
          <Box as="span" color="brand.blue">
            customised{' '}
          </Box>
          <Box as="span">using our building blocks</Box>
        </Heading>
        <Text
          fontSize={{ base: '24px', lg: '28px' }}
          fontFamily="roboto"
          textAlign="center"
          pb="24px"
          color="brand.blue"
        >
          Do business your way
        </Text>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          wrap="wrap"
          justify="center"
          align="center"
          gridGap="10"
          px={{ base: 0, xl: '130px' }}
        >
          {taskTileDetails.map(({ heading, taskItems }) => (
            <TaskItemTile
              key={heading}
              heading={heading}
              taskItems={taskItems}
            />
          ))}
        </Flex>
      </Box>
      <Box
        pb={{ base: '35px', lg: '42px' }}
        pt={{ base: '32px', lg: '42px' }}
        px="16px"
        bg="brand.light-gray"
      >
        <Heading
          as="h3"
          size="lg"
          textAlign="center"
          px={{ base: 4, lg: '200px' }}
          pt={{ base: '20px', lg: '24px' }}
          pb={{ base: '4px' }}
        >
          <Box as="span">Stored in one place, </Box>
          <Box as="span" color="brand.blue">
            validated and synced{' '}
          </Box>
          <Box as="span">with your existing tools</Box>
        </Heading>
        <Text
          fontSize={{ base: '24px', lg: '28px' }}
          fontFamily="roboto"
          textAlign="center"
          color="brand.blue"
        >
          Conveniently submit what is needed
        </Text>
        <Integrations bgColor="brand.light-gray" isMyobLogoDisplayed />
      </Box>
    </>
  );
};

type TemplateTileProps = {
  text: string;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  color: string;
};

const TemplateTile: React.FC<TemplateTileProps> = ({ icon, text, color }) => (
  <Flex
    direction="column"
    color={color}
    border="2px solid black"
    boxShadow="-12px 13px 0px 0px"
    rounded="8px"
    align="center"
    width="240px"
    minH="140px"
    px="24px"
    pt="24px"
    pb="12px"
  >
    <Icon w={50} h={50} as={icon} />
    <Text textAlign="center" width="140px" color="black">
      {text}
    </Text>
  </Flex>
);

type TaskItemTileProps = { heading: string; taskItems: TaskItemProps[] };

const TaskItemTile: React.FC<TaskItemTileProps> = ({ heading, taskItems }) => (
  <Flex
    direction="column"
    bgColor="brand.white"
    border="2px solid black"
    boxShadow="-12px 13px 0px 0px rgba(0,0,0,0.75)"
    rounded="8px"
    align="center"
    width="340px"
    minH="464px"
    px="24px"
    pt="24px"
    pb="24px"
  >
    <Heading
      as="h3"
      fontSize="24px"
      fontFamily="roboto"
      textAlign="center"
      px={{ base: 4, lg: '20px' }}
      pt={{ base: '20px', lg: '24px' }}
      pb={{ base: '55px', lg: '4px' }}
    >
      {heading}
    </Heading>
    <Divider />
    {taskItems.map(({ icon, text }) => (
      <TaskItem key={text} icon={icon} text={text} />
    ))}
  </Flex>
);

type TaskItemProps = {
  text: string;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
};

const TaskItem: React.FC<TaskItemProps> = ({ icon, text }) => (
  <Flex align="center" px="24px" pt="24px" pb="12px" justifyContent="center">
    <Icon w={50} h={50} as={icon} />
    <Text textAlign="left" width="124px" pt={2} pl={3}>
      {text}
    </Text>
  </Flex>
);
