import * as React from 'react';
import { Flex, Heading, Icon, Text } from '@chakra-ui/react';
import Esign from '../../assets/icons/esign-purple.svg';
import CustomForms from '../../assets/icons/custom-forms.svg';
import Document from '../../assets/icons/document-collection.svg';
import { BsPersonBoundingBox } from 'react-icons/bs';
import { RiGovernmentLine } from 'react-icons/ri';
import { IconType } from 'react-icons';
import { TbFileCertificate } from 'react-icons/tb';
import { AiOutlineFileSync } from 'react-icons/ai';

const features = [
  { text: 'Government Forms', icon: RiGovernmentLine, color: '#25BEFF' },
  { text: 'Custom Forms', icon: CustomForms, color: '#8CFFA5' },
  { text: 'Document Collection', icon: Document, color: '#FFADA1' },
  { text: 'E-Sign', icon: Esign, color: '#DA9DFF' },
  {
    text: 'Identity Verification',
    icon: BsPersonBoundingBox,
    color: '#9FFF25',
  },
  {
    text: 'Licence & Cert. Monitoring',
    icon: TbFileCertificate,
    color: '#FF7325',
  },
  { text: 'Data Sync.', icon: AiOutlineFileSync, color: '#25BEFF' },
];

export const FeatureTiles: React.FC = () => {
  return (
    <Flex
      bg="brand.egg"
      pt="70px"
      pb="30px"
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Heading
        as="h3"
        fontSize="32px"
        textAlign="center"
        px={{ base: 4, lg: '200px' }}
        pt={{ base: '10px', lg: '10px' }}
        pb={{ base: '15px', lg: '15px' }}
      >
        Your Process Toolkit
      </Heading>
      <Flex
        flexWrap="wrap"
        gridGap="6"
        justifyContent="center"
        maxW="1300px"
        px={2}
      >
        {features.map(({ text, icon, color }) => (
          <FeatureTile key={text} text={text} icon={icon} color={color} />
        ))}
      </Flex>
    </Flex>
  );
};

type FeatureTileProps = {
  text: string;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>> | IconType;
  color: string;
};

const FeatureTile: React.FC<FeatureTileProps> = ({ icon, text, color }) => (
  <Flex
    direction="column"
    bgColor="brand.white"
    color={color}
    border="2px solid black"
    boxShadow="-12px 13px 0px 0px"
    rounded="14px"
    align="center"
    width="150px"
    px="25px"
    pt="25px"
    pb="15px"
  >
    <Icon color={`${color}.300`} w={25} h={25} as={icon} mb={2} />
    <Text textAlign="center" fontSize="12px" color="black" width="100px">
      {text}
    </Text>
  </Flex>
);
