import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { HeroImage } from './HeroImage';
import { HeroText } from './HeroText';

type HeroSectionProps = {
  heading: string;
  subHeading: string;
  heroImage: React.ReactNode;
};

export const HeroSection: React.FC<HeroSectionProps> = ({
  heading,
  subHeading,
  heroImage,
}) => {
  return (
    <Flex
      direction={{ base: 'column', lg: 'row-reverse' }}
      justify="center"
      align="stretch"
    >
      <HeroImage heroImage={heroImage} />
      <HeroText heading={heading} subHeading={subHeading} />
    </Flex>
  );
};
