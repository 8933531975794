import * as React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { BenefitCard, BenefitCardDetails } from './BenefitCard';

type BenefitsListProps = {
  benefits: Omit<BenefitCardDetails, 'position'>[];
};

export const BenefitsList: React.FC<BenefitsListProps> = ({ benefits }) => (
  <Flex
    direction="column"
    px={4}
    pt={{ base: '25px', md: '129px' }}
    pb={{ md: '109px' }}
  >
    <Heading fontSize={{ base: '42px', lg: '56px' }} textAlign="center">
      <Box as="span">Benefits our customers </Box>
      <Box as="span" color="brand.blue">
        love
      </Box>
    </Heading>
    <Flex
      mt={{ base: '25px', md: '75px' }}
      px={5}
      justifyContent="center"
      flexWrap="wrap"
    >
      {benefits.map(({ heading, body }, index) => (
        <BenefitCard
          key={heading}
          heading={heading}
          position={index + 1}
          body={body}
        />
      ))}
    </Flex>
  </Flex>
);
