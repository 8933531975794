import * as React from 'react';
import { Text, Flex } from '@chakra-ui/react';

export type BenefitCardDetails = {
  heading: string;
  body: string;
  position: number;
};

type BenefitCardProps = BenefitCardDetails;

export const BenefitCard: React.FC<BenefitCardProps> = ({
  heading,
  body,
  position,
}) => (
  <Flex
    direction="column"
    px={3}
    py={5}
    m={6}
    border="2px solid"
    borderColor="brand.light-gray"
    rounded="sm"
    boxShadow="0px 34px 84px #193F9214"
    width="350px"
    transition="all 0.3s ease"
    _hover={{
      color: 'brand.blue',
      border: '2px solid',
      transform: 'scale(1.02)',
      borderColor: 'brand.blue',
    }}
  >
    <Text fontSize="36px" p={4}>
      {position}
    </Text>
    <Text fontWeight="600" fontSize="18px" color="brand.blue" p={3}>
      {heading}
    </Text>
    <Text p={3} color="black">
      {body}
    </Text>
  </Flex>
);
