import * as React from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { CgChevronRight } from 'react-icons/cg';

const ProblemPoint: React.FC<{ text: string }> = ({ text }) => (
  <Flex gridGap="10">
    <Flex>
      <Icon
        p="2px"
        borderRadius="50%"
        bg="brand.blue"
        color="brand.white"
        w="28px"
        h="28px"
        as={CgChevronRight}
        mr="1px"
      />
    </Flex>
    <Text fontSize="18px" maxW="550px">
      {text}
    </Text>
  </Flex>
);

export const ProblemBulletList: React.FC<{ problemList: string[] }> = ({
  problemList,
}) => (
  <Flex gridGap="6" direction="column" px={4}>
    {problemList.map((prob) => (
      <ProblemPoint key={prob} text={prob} />
    ))}
  </Flex>
);
