import { Heading, Box, Flex, Icon, Text } from '@chakra-ui/react';
import * as React from 'react';
import Automate from '../../../assets/icons/automate.svg';
import Digitise from '../../../assets/icons/digitise-paper-processes.svg';
import Risk from '../../../assets/icons/reduce-risk.svg';
import Monitor from '../../../assets/icons/monitor-maintain-documents.svg';

export const ProblemSolutionSummary: React.FC = () => {
  const solutionDetails: SolutionTileProps[] = [
    {
      text: 'Automate the collection and sync of business information',
      icon: Automate,
    },
    {
      text: 'Digitise paper processes',
      icon: Digitise,
    },
    {
      text: 'Monitor and maintain compliance documents, licences and certificates',
      icon: Monitor,
    },
    {
      text: 'Minimise the risk associated with business compliance',
      icon: Risk,
    },
  ];

  return (
    <Box pb={{ base: '35px', lg: '40px' }} bg="brand.egg">
      <Heading
        as="h2"
        size="lg"
        textAlign="center"
        px={4}
        pt={{ base: '25px', lg: '115px' }}
        pb={{ base: '55px' }}
      >
        <Box as="span">Managing </Box>
        <Box as="span" color="brand.blue">
          business information
        </Box>
        <br />
        <Box as="span">shouldn&apos;t be so hard</Box>
      </Heading>
      <Heading
        color="brand.blue"
        as="h3"
        fontFamily="roboto"
        fontWeight="600"
        fontSize={{ base: '24px', lg: '36px' }}
        textAlign={{ base: 'center' }}
        pb="25px"
      >
        If you are looking for a way to
      </Heading>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        alignContent="center"
        wrap="wrap"
        justify="center"
        align="center"
        gridGap={{ base: 2, lg: '10' }}
        px={{ base: '12px', xl: '130px' }}
      >
        {solutionDetails.map(({ text, icon }) => (
          <SolutionTile key={text} icon={icon} text={text} />
        ))}
      </Flex>
      <Heading
        as="h2"
        size="lg"
        textAlign="center"
        px={4}
        pt={{ base: '25px', lg: '115px' }}
        pb={{ base: '24px', lg: '55px' }}
      >
        <Box as="span">With </Box>
        <Box as="span" color="brand.blue">
          Canyou{' '}
        </Box>
        <Box as="span">you can</Box>
      </Heading>
    </Box>
  );
};

type SolutionTileProps = {
  text: string;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
};

const SolutionTile: React.FC<SolutionTileProps> = ({ icon, text }) => (
  <Flex
    direction={{ base: 'row', lg: 'column' }}
    bgColor="brand.white"
    boxShadow="0px 24px 32px #000F2F14"
    rounded="14px"
    align="center"
    maxW={{ base: '600px', lg: '220px' }}
    alignSelf="stretch"
    py={{ base: '16px', lg: '24px' }}
    px={{ base: '20px', lg: '24px' }}
    gridGap={{ base: 4, lg: 2 }}
  >
    <Icon color="brand.blue" w={50} h={50} as={icon} />
    <Text textAlign={{ base: 'left', lg: 'center' }}>{text}</Text>
  </Flex>
);
