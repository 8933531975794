import * as React from 'react';
import { Heading, Box, Flex, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ProblemBulletList } from '../../features/licence-and-certs/ProblemSection/ProblemBulletList';

type Data = {
  ladyCheckLicences: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const problemList: string[] = [
  'Follow ups, emails texting and phone call take time',
  'Manual validations are timely and susceptible to error',
  'Manual setup in systems is repetitive and wastes resources',
];

export const ProblemSection1: React.FC = () => {
  const { ladyCheckLicences } = useStaticQuery<Data>(
    graphql`
      {
        ladyCheckLicences: file(
          relativePath: { eq: "problems/lady-checking-licences.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );
  return (
    <>
      <Flex justify="center">
        <Heading
          as="h2"
          size="lg"
          textAlign="center"
          px={4}
          pb={{ base: '55px' }}
          maxWidth="1000px"
        >
          <Box as="span">Even with all your </Box>
          <Box as="span" color="brand.blue">
            apps{' '}
          </Box>
          <Box as="span">you are still manually chasing information</Box>
        </Heading>
      </Flex>
      <Flex
        direction={{
          base: 'column',
          lg: 'row',
        }}
        justify="space-around"
        align="center"
        px={{ base: '16px', xl: '130px' }}
        gridGap="6"
      >
        <Box>
          <GatsbyImage
            alt="Lady checking licences"
            image={ladyCheckLicences.childImageSharp.gatsbyImageData}
            style={{
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        </Box>
        <Flex direction="column" gridGap={6} pb={{ base: '12px', lg: '24px' }}>
          <Text
            fontSize={['18px', '24px']}
            maxW="550px"
            textAlign={{ base: 'center', lg: 'left' }}
          >
            Even with an eco-system of business apps, gaps arise requiring
            follow-ups. Moving information between apps accurately and
            efficiently can become a disconnected manual burden.
          </Text>
          <Heading
            as="h3"
            size="md"
            maxW="550px"
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Box as="span" color="brand.blue">
              Disconnection
            </Box>{' '}
            is costing businesses time and money
          </Heading>
          <ProblemBulletList problemList={problemList} />
        </Flex>
      </Flex>
    </>
  );
};
