import * as React from 'react';
import { Heading, Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ProblemBulletList } from '../../features/licence-and-certs/ProblemSection/ProblemBulletList';

type Data = {
  emailSmsSpreadsheet: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const problemsList3: string[] = [
  'Some systems can be complex, expensive or a combination of both',
  'Current systems do part of the work. Gaps are filled with email, spreadsheets and messages',
  'Unique business processes with  specific requirements',
  'Potential human errors means manual validation of information',
];

export const ProblemSection3: React.FC = () => {
  const { emailSmsSpreadsheet } = useStaticQuery<Data>(
    graphql`
      {
        emailSmsSpreadsheet: file(
          relativePath: { eq: "problems/email-sms-spreadsheet.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );
  return (
    <>
      <Heading
        as="h2"
        size="lg"
        textAlign="center"
        px={4}
        pt={{ base: '25px', lg: '55px' }}
        pb={{ base: '55px' }}
      >
        <Box as="span">...why is it</Box>
        <br />
        <Box as="span" color="brand.blue">
          still challenging?
        </Box>
      </Heading>
      <Flex
        direction={{
          base: 'column',
        }}
        justify="space-around"
        align="center"
        px={{ base: 0, xl: '130px' }}
        gridGap="6"
      >
        <Box px={2}>
          <GatsbyImage
            alt="email sms spreadsheet"
            image={emailSmsSpreadsheet.childImageSharp.gatsbyImageData}
            style={{
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        </Box>
        <ProblemBulletList problemList={problemsList3} />
      </Flex>
      <Flex justify="center">
        <Heading
          as="h2"
          size="lg"
          textAlign="center"
          px={4}
          pt={{ base: '25px', lg: '55px' }}
          pb={{ base: '24px' }}
          maxWidth="1000px"
        >
          <Box as="span">What if you could rely on an app to </Box>
          <Box as="span" color="brand.blue">
            fill the gaps...
          </Box>
        </Heading>
      </Flex>
    </>
  );
};
