import { Box, Flex, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

type Data = {
  xeroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  myobImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  deputyImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const Integrations: React.FC<{
  isMyobLogoDisplayed: boolean;
  isDisplaySmall?: boolean;
  bgColor: string;
}> = ({ isMyobLogoDisplayed, isDisplaySmall, bgColor }) => {
  const { deputyImage, xeroImage, myobImage } = useStaticQuery<Data>(
    graphql`
      {
        xeroImage: file(relativePath: { eq: "xero-connected.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        myobImage: file(relativePath: { eq: "myob-logo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        deputyImage: file(
          relativePath: { eq: "deputy-logo-full-color-rgb.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
      }
    `,
  );
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      px={5}
      pt={{ base: '25px', lg: isDisplaySmall ? '15px' : '50px' }}
      bg={bgColor}
    >
      {!isDisplaySmall && (
        <Text fontWeight="500" fontSize={isDisplaySmall ? '16px' : '22px'}>
          Trusted by
        </Text>
      )}
      <Flex align="center" justify="center">
        <Box maxWidth={isDisplaySmall ? '160px' : '270px'} px={5}>
          <GatsbyImage
            alt="Xero logo"
            image={xeroImage.childImageSharp.gatsbyImageData}
          />
        </Box>
        {isMyobLogoDisplayed && (
          <Box maxWidth={isDisplaySmall ? '160px' : '270px'} px={5}>
            <GatsbyImage
              alt="MYOB logo"
              image={myobImage.childImageSharp.gatsbyImageData}
            />
          </Box>
        )}
        <Box maxWidth={isDisplaySmall ? '160px' : '320px'} px={5}>
          <GatsbyImage
            alt="Deputy logo"
            image={deputyImage.childImageSharp.gatsbyImageData}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
