import * as React from 'react';
import { Heading, Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ProblemBulletList } from '../../features/licence-and-certs/ProblemSection/ProblemBulletList';

type Data = {
  postItNotes: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const problemList: string[] = [
  'Collecting specific information from the right team members ',
  'Collecting specific information from other businesses',
  'Sharing training and knowledge resources',
  'Monitoring and maintaining licences and certificates',
  'Tracking information into the future to ensure compliance',
];

export const ProblemSection2: React.FC = () => {
  const { postItNotes } = useStaticQuery<Data>(
    graphql`
      {
        postItNotes: file(relativePath: { eq: "problems/post-it-notes.png" }) {
          childImageSharp {
            gatsbyImageData(width: 500, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );
  return (
    <>
      <Flex justify="center">
        <Heading
          as="h2"
          size="lg"
          textAlign="center"
          px={4}
          pt={{ base: '45px', lg: '55px' }}
          pb={{ base: '55px' }}
          maxWidth="1000px"
        >
          <Box as="span">
            ...but managing the flow of information in a business is not a one
            size fits all...
          </Box>
        </Heading>
      </Flex>
      <Flex
        direction={{
          base: 'column-reverse',
          lg: 'row',
        }}
        justify="space-around"
        align="center"
        px={{ base: '16px', xl: '130px' }}
        gridGap="6"
      >
        <ProblemBulletList problemList={problemList} />

        <Box>
          <GatsbyImage
            alt="managing post-it notes"
            image={postItNotes.childImageSharp.gatsbyImageData}
            style={{
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
