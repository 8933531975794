import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { Integrations } from '../components/Integrations';
import { FeatureTiles } from '../components/FeatureTiles';
import { HeroSection } from '../components/HeroSection';
import { ProblemSolutionSummary } from '../components/pages/home/ProblemSolutionSummary';
import { ProblemSection } from '../components/pages/home/ProblemSection';
import { SolutionSection } from '../components/pages/home/SolutionSection';
import { TestimonialSection } from '../components/TestimonialSection';
import { PricingSection } from '../components/PricingSection';
import { AnswerObjectionsSection } from '../components/AnswerObjectionsSection';
import { QRScanSection } from '../components/QRScanSection';
import { FinalCTA } from '../components/FinalCTA';
import { BenefitsList } from '../components/BenefitsList';

const benefits = [
  {
    heading: 'Easy setup',
    body: 'Setup in 10 minutes and avoid costly implementations. Canyou integrates with your existing systems so you can keep using your existing payroll and time systems.',
  },
  {
    heading: 'Easy for the team',
    body: 'Team members use Canyou from their own device. Set reminders with Canyou to send instant notifications when the team needs to interact.',
  },
  {
    heading: 'Everything in one spot',
    body: 'Canyou is the single place for your team to keep track of the status of training, compliance and operations tasks',
  },
  {
    heading: 'Reminders and notifications',
    body: 'Setup reminders and notifications to keep track of important dates like licence expiries or annual pay reviews. Notifications get sent out to the team members to make sure you never miss anything',
  },
  {
    heading: 'Safe and secure',
    body: 'Transfer sensitive data safely and securely. No more data leak risks through emails and text messages',
  },
];

type Data = {
  heroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  site: {
    siteMetadata: {
      title: string;
    };
  };
};

const Home: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Homepage: Viewed Main Landing Page');
  }, [mixpanel]);

  const { heroImage } = useStaticQuery<Data>(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        heroImage: file(
          relativePath: {
            eq: "screenshots/tax-file-number-declaration-software.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 900, layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  return (
    <Layout>
      <SEO
        title="Australian small business process automation | Streamline and communicate with Canyou"
        description="Compliance, training and operations for business teams. Build workflows, assign tasks and get work done. Secured in one place and automated."
      />
      <HeroSection
        heading="The Process App to Fill the Gaps"
        subHeading="Collect and coordinate documents, forms, licences and certificates for businesses and their teams. Stored in one spot. Synced to your existing app stack."
        heroImage={
          <GatsbyImage
            alt="small business workflow software"
            image={heroImage.childImageSharp.gatsbyImageData}
            style={{
              maxWidth: `90vh`,
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        }
      />
      <FeatureTiles />
      <Integrations isMyobLogoDisplayed isDisplaySmall bgColor="brand.egg" />
      <ProblemSolutionSummary />
      <ProblemSection />
      <SolutionSection />
      <BenefitsList benefits={benefits} />
      <TestimonialSection />
      <PricingSection />
      <AnswerObjectionsSection />
      <QRScanSection />
      <FinalCTA />
    </Layout>
  );
};

export default Home;
